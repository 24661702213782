<template>
  <v-dialog
    v-model="dialog"
    max-width="700"
    persistent
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card>
      <v-toolbar color="primary" dark flat short class="px-2">
        <v-toolbar-title>Supplier</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="cancel()" icon><v-icon>fas fa-times</v-icon></v-btn>
      </v-toolbar>

      <v-card-text class="mt-12">
        <v-form @submit.prevent>

          <v-autocomplete
            outlined
            clearable
            clear-icon="fas fa-times"
            class="mb-2"
            v-model="attributes.supplier_id"
            :error-messages="errors.supplier_id"
            item-text="name"
            item-value="id"
            :items="suppliers"
            label="Suppliers"
          ></v-autocomplete>

          <v-text-field
            outlined
            class="mb-2"
            v-model="attributes.sku"
            :error-messages="errors.sku"
            label="Article Number"
          ></v-text-field>

          <number-field
            outlined
            class="mb-2"
            v-model="attributes.purchase_price"
            :error-messages="errors.purchase_price"
            label="Purchase Price"
            prefix="€"
            decimals="2"
            decimal-separator="."
          ></number-field>



        </v-form>
      </v-card-text>

      <v-card-actions class="px-6 pb-8">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="px-5"
          large
          outlined
          depressed
          @click="cancel()"
        >{{ cancelButtonText }}</v-btn>
        <v-btn
          color="primary"
          class="px-5"
          large
          depressed
          @click="save()"
        >{{ saveButtonText }}</v-btn>
      </v-card-actions>

    </v-card>

  </v-dialog>
</template>

<script>
import { ref, watch } from 'vue'
import NumberField from '@/components/NumberField'

export default {
  components: {
    NumberField,
  },
  props: {
    value: Boolean,
    attributes: Object,
    errors: Object,
    suppliers: Array,
    cancelButtonText: {
      default: 'Cancel',
    },
    saveButtonText: {
      default: 'Save',
    },
  },
  setup(props, context) {
    const dialog = ref(false)

    watch(() => props.value, val => {
      dialog.value = val
    })

    watch(dialog, val => {
      context.emit('input', val)
    })

    function save() {
      context.emit('save')
    }

    function cancel() {
      dialog.value = false
      context.emit('cancel')
    }

    function show() {
      dialog.value = true
    }

    function hide() {
      dialog.value = false
    }

    return {
      dialog,

      save,
      cancel,

      show,
      hide,
    }
  }}
</script>